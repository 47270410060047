import PlanTrip from 'modals/planTrip/PlanTrip';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import useProtectedRoutes from 'routes/useProtectedRoutes';
import Header from './partials/header/Header';
import Sidebar from './partials/sidebar/Sidebar';
import { useLocation } from 'react-router-dom';
import {
  handlPlanTripModal,
  handlSidebar,
  setNotReadNotificationCount,
  toggleHomePage,
} from 'redux/dashbbaord/dashboardSlice';
import GuestModal from 'modals/guest/GuestModal';
import { useJsApiLoader } from '@react-google-maps/api';
import { toggleLogin, updateUserFirebaseToken } from 'redux/auth/authSlice';
import { toast } from 'react-toastify';
import { sessionMessage } from 'helper/messages';
import PlanTripStepsModal from 'modals/planTripSteps/planTripStepsModal';
import NoTripModal from 'modals/noTrip/noTripModal';
import NoVehicleModal from 'modals/noVehicle/noVehicleModal';
import NoChargingModal from 'modals/noCharging/noChargingModal';
import NoPaymentMethodModal from 'modals/noPaymentMethod/noPaymenMethodModal';
import { getBrowserInfo } from 'utls/checkBrowser';
import useApiHook from 'hooks/useApiHook';
import { getToken } from '@firebase/messaging';
import { firebaseMessaging } from 'config/firebase/firebaseClient';
import { addNotifications } from 'redux/dashbbaord/dashboardSlice';
import { onMessage } from '@firebase/messaging';

const DashboardLayout = () => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries: ['places'],
  });
  const { handleApiCall } = useApiHook();
  const { auth, dashboard } = useSelector((state) => state);
  const [isGuestModal, setIsGuestModal] = useState(false);
  const [isPlanTripModal, setIsPlanTripModal] = useState(false);
  const [isNoTripModal, setIsNoTripModal] = useState(false);
  const [isNoVehicleModal, setIsNoVehicleModal] = useState(false);
  const [isNoChargingModal, setIsNoChargingModal] = useState(false);
  const [isNoPaymentMethodModal, setIsNoPaymentMethodModal] = useState(false);
  const routes = useProtectedRoutes(auth?.userInfo);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const updateToken = async (token) => {
    const browser = await getBrowserInfo();
    const data = {
      platform: browser,
      token: token,
    };

    const result = await handleApiCall({
      method: 'post',
      url: '/user/update-firebase-token',
      data,
      token: auth?.userInfo?.accessToken,
    });

    if (result?.status === 200) {
      console.log('Token updated successfully.');
      dispatch(updateUserFirebaseToken(data));
    }
  };

  useEffect(() => {
    if (
      !auth?.isLogin &&
      !auth?.userInfo?.accessToken &&
      window.location.pathname === '/home'
    ) {
      dispatch(
        toggleLogin({
          isLogin: true,
          userInfo: {
            user: {
              name: `Guest User`,
              isActive: false,
              role: 'guest',
            },
          },
        })
      );
      dispatch(toggleHomePage(true));
      toast.success('Viewing as a guest.');
    }
  }, [auth?.isLogin]);

  useEffect(() => {
    if (
      (auth?.isLogin &&
        !auth?.userInfo?.accessToken &&
        auth?.userInfo?.user?.role !== 'guest') ||
      auth?.userInfo?.user?.role === 'admin'
    ) {
      toast.info(sessionMessage);
      dispatch(toggleLogin({ isLogin: false, userInfo: null }));
      navigate('/login');
    }
  }, []);

  useEffect(() => {}, [dashboard?.isSidebar]);

  useEffect(() => {
    // if (window?.innerWidth <= 1026) dispatch(handlSidebar(false));
    dispatch(handlSidebar(false));
    dispatch(handlPlanTripModal(false));
  }, [location]);

  useEffect(() => {
    if (auth?.isLogin && auth?.userInfo?.accessToken) {
      Notification.requestPermission().then((permission) => {
        if (permission !== 'granted')
          return toast.error('You have denied the notifications permission.');
        getToken(firebaseMessaging, {
          vapidKey:
            'BIm7MLtDC8KC1qbDaKJMZIomPPk0A0Du4kItn6Mo2z_GYhXSAOZC2t7OzQGdolaLIu_FSI5gYcD1p2-IdZlmAWk',
        }).then((currentToken) => {
          if (
            currentToken &&
            !auth?.userInfo?.user?.firebaseTokens.some(
              (t) => t.token === currentToken
            )
          )
            return updateToken(currentToken);
        });
      });
    }
  }, []);


  useEffect(() => {
    onMessage(firebaseMessaging, async (payload) => {
      try {
        const notification = [
          {
            messageId: payload?.messageId,
            sender: payload?.from,
            title: payload?.notification?.title,
            message: payload?.notification?.body,
            url: payload?.data?.url,
            isRead: false,
            createdAt: Date.now(),
          },
        ];
        const result = await handleApiCall({
          method: 'get',
          url: '/notifications/all',
          token: auth?.userInfo?.accessToken,
        });
        if (result?.status === 200) {
          dispatch(setNotReadNotificationCount(result.data?.notReadCount));
        }
        dispatch(addNotifications(notification));
      } catch (error) {
        console.error('Error handling message:', error);
      }
    });
  }, [firebaseMessaging]);

  return (
    <div className='ez__DashboardLayout relative w-full z-10'>
      {location?.pathname !== '/' &&
        location?.pathname !== '/page-not-found' && (
          <Header
            setIsPlanTripModal={setIsPlanTripModal}
            setIsNoTripModal={setIsNoTripModal}
            setIsNoVehicleModal={setIsNoVehicleModal}
          />
        )}
      <div className='ez__LayoutDiv relative flex flex-wrap w-full h-full'>
        {location?.pathname !== '/' &&
          location?.pathname !== '/page-not-found' && (
            <div
              className={`${
                dashboard?.isSidebar ? 'block' : 'hidden'
              } ez__Sidebar w-full bg-white h-full`}
            >
              <Sidebar
                setIsGuestModal={setIsGuestModal}
                setIsPlanTripModal={setIsPlanTripModal}
                setIsNoTripModal={setIsNoTripModal}
                setIsNoVehicleModal={setIsNoVehicleModal}
                setIsNoChargingModal={setIsNoChargingModal}
                setIsNoPaymentMethodModal={setIsNoPaymentMethodModal}
              />
            </div>
          )}
        <div
          className={`${
            location?.pathname !== '/page-not-found'
              ? 'ez__LayoutPages p-0 w-full outline-none focus:outline-none'
              : ''
          } h-full bg-ezMidWhite`}
        >
          {auth?.isLogin && routes}
        </div>
        {location?.pathname !== '/' &&
          location?.pathname !== '/page-not-found' && <PlanTrip />}
        {isGuestModal && (
          <GuestModal
            isGuestModal={isGuestModal}
            setIsGuestModal={setIsGuestModal}
          />
        )}
        {isPlanTripModal && (
          <PlanTripStepsModal
            isPlanTrip={isPlanTripModal}
            setIsPlanTripModal={setIsPlanTripModal}
          />
        )}
        {isNoTripModal && (
          <NoTripModal
            isNoTripModal={isNoTripModal}
            setIsNoTripModal={setIsNoTripModal}
          />
        )}
        {isNoVehicleModal && (
          <NoVehicleModal
            isNoVehicleModal={isNoVehicleModal}
            setIsNoVehicleModal={setIsNoVehicleModal}
          />
        )}
        {isNoChargingModal && (
          <NoChargingModal
            isNoChargingModal={isNoChargingModal}
            setIsNoChargingModal={setIsNoChargingModal}
          />
        )}
        {isNoPaymentMethodModal && (
          <NoPaymentMethodModal
            isNoPaymentMethodModal={isNoPaymentMethodModal}
            setIsNoPaymentMethodModal={setIsNoPaymentMethodModal}
          />
        )}
      </div>
    </div>
  );
};

export default DashboardLayout;
