import { Fragment, useEffect, useState } from 'react';
import AddVehicle from './AddVehicle';
import { useDispatch, useSelector } from 'react-redux';
import VehicleCard from 'components/myVehicle/VehicleCard';
import Spiner from 'helper/Spiner';
import DeleteVehicle from 'modals/vehicles/DeleteVehicle';
import UpdateVehicle from './UpdateVehicle';
import {
  toggleIsVehicleApiCall,
  toggleVehiclesPage,
} from 'redux/dashbbaord/dashboardSlice';
import useApiHook from 'hooks/useApiHook';
import { handleUserVehicles } from 'redux/auth/authSlice';
import { plus } from 'helper/helper';
import AdCard from 'components/Advertisment/AdCard';

const MyVehicle = () => {
  const { auth, dashboard } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { handleApiCall, isApiLoading } = useApiHook();
  const [isVehicleModal, setIsVehicleModal] = useState(false);
  const [isVehicleUpdate, setIsVehicleUpdate] = useState(false);
  const [editedVehicle, setEditedVehicle] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [delVehicle, setDelVehicle] = useState({
    isDelVehicle: false,
    vehicle: null,
  });
  const [vehicles, setVehicles] = useState([]);

  const getMyVehicles = async (userInfo) => {
    setIsLoading(true);
    const result = await handleApiCall({
      method: 'get',
      url: `/vehicle/${userInfo?.user?._id}`,
    });
    if (result.status === 200) {
      dispatch(handleUserVehicles(result?.data));
      setVehicles(result?.data);
      dispatch(toggleIsVehicleApiCall(false));
    }
    setIsLoading(false);
  };

  const updateUserVehicles = async (vehicle) => {
    let updatedVehicles =
      vehicles?.map((item) => (item?._id === vehicle?._id ? vehicle : item)) ||
      [];
    dispatch(handleUserVehicles(updatedVehicles));
    setVehicles(updatedVehicles);
  };

  const handleUpdate = (vehicle) => {
    setIsVehicleUpdate(true);
    setEditedVehicle(vehicle);
  };

  useEffect(() => {
    if (!dashboard?.isVehicleApiCall && auth?.userInfo?.user?._id)
      getMyVehicles(auth?.userInfo);
    dispatch(toggleVehiclesPage(true));
  }, []);

  useEffect(() => {
    if (dashboard?.isVehicleApiCall && auth?.userInfo?.user?._id)
      getMyVehicles(auth?.userInfo);
  }, [dashboard?.isVehicleApiCall]);

  return (
    <div className='ez__MyVehicle w-full h-full bg-ezMidWhite px-4 py-10 md:p-10'>
      {isVehicleModal ? (
        <AddVehicle
          isVehicleModal={isVehicleModal}
          setIsVehicleModal={setIsVehicleModal}
          getMyVehicles={(vehicle) => {
            dispatch(handleUserVehicles([...vehicles, vehicle]));
            setVehicles([...vehicles, vehicle]);
          }}
        />
      ) : editedVehicle && isVehicleUpdate ? (
        <UpdateVehicle
          editedVehicle={editedVehicle}
          isVehicleUpdate={isVehicleUpdate}
          setIsVehicleUpdate={setIsVehicleUpdate}
          getMyVehicles={updateUserVehicles}
        />
      ) : (
        <>
          <div className='ez__Title w-full flex md:items-center justify-start mb-8 flex-col md:flex-row'>
            <h3 className='text-ezBlack font-semibold text-3xl mb-3 md:mb-0'>
              My Vehicles
            </h3>
          </div>

          <div className='grid grid-cols-1 md:gap-x-10 md:flex md:flex-row'>
            <div
              className={`${
                isApiLoading
                  ? 'flex flex-row flex-wrap gap-6 w-full'
                  : 'grid grid-cols-1 md:grid-cols-2  xl:grid-cols-4 gap-4 mb-4  w-full h-full'
              }`}
            >
              {isApiLoading ? (
                <div className='block w-full h-96'>
                  <Spiner color='ezGreen' />
                </div>
              ) : vehicles?.length > 0 ? (
                <>
                  <div
                    className='ez__Tabs h-72 md:h-72 lg:h-80 flex flex-col justify-center items-center border border-dashed border-ezGreen rounded-md hover:cursor-pointer hover:bg-ezLightGreen hover:text-ezGreen transition-all duration-300 ease-in-out'
                    onClick={() => setIsVehicleModal(!isVehicleModal)}
                  >
                    <span>{plus}</span>
                    <p className='mt-2 text-ezGreen'>Add New Vehicle</p>
                  </div>
                  {vehicles?.map((vehicle, index) => (
                    <Fragment key={vehicle._id}>
                      {!isApiLoading &&
                        index % 4 === 0 &&
                        index !== 0 &&
                        dashboard?.ads?.length > 0 && (
                          <div className='md:hidden flex md:p-3 flex-col md:flex-row items-center justify-center overflow-hidden'>
                            <AdCard
                              alignment={'mobile'}
                              ads={dashboard?.ads[0]}
                              className='md:hidden w-full h-full object-cover'
                            />
                          </div>
                        )}
                      <VehicleCard
                        vehicle={vehicle}
                        setDelVehicle={setDelVehicle}
                        handleUpdate={handleUpdate}
                      />
                    </Fragment>
                  ))}
                </>
              ) : (
                <div className='col-span-3 py-32 w-full flex items-center justify-center flex-col h-auto'>
                  <img
                    src='/assets/svgs/noVehicle.svg'
                    alt='No Trip'
                    className='w-32 md:w-56 h-auto mb-5'
                  />
                  <p className='text-ezGray text-sm md:text-base mb-5'>
                    There is no vehicle
                  </p>
                </div>
              )}
            </div>
            {!isApiLoading && dashboard?.ads?.length > 0 && (
              <div className='hidden md:block'>
                <AdCard
                  alignment={'vertical'}
                  ads={dashboard?.ads[0]}
                  className='top-1/3 transform -translate-y-1/3 lg:w-40 md:w-36 w-24'
                />
              </div>
            )}
          </div>
        </>
      )}
      {delVehicle && (
        <DeleteVehicle
          userInfo={auth?.userInfo}
          delVehicle={delVehicle}
          vehicles={vehicles}
          setVehicles={setVehicles}
          setDelVehicle={setDelVehicle}
          getMyVehicles={getMyVehicles}
        />
      )}
    </div>
  );
};

export default MyVehicle;
