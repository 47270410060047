import { Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Select from 'react-select';
import Spiner from 'helper/Spiner';
import { MdCancel } from 'react-icons/md';
import { FileUploader } from 'react-drag-drop-files';
import { colourStyles } from 'helper/helper';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { toogleVehicleManufactures } from 'redux/dashbbaord/dashboardSlice';
import useApiHook from 'hooks/useApiHook';
import GrayBtn from 'components/btns/GrayBtn';

export default function AddVehicle({
  isVehicleModal,
  setIsVehicleModal,
  getMyVehicles,
}) {
  const { auth, dashboard } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { handleApiCall, isApiLoading } = useApiHook();
  const cancelButtonRef = useRef(null);
  const [manufactureList, setManufactureList] = useState([]);
  const [models, setModels] = useState([]);
  const [file, setFile] = useState(null);
  const [isImagePreview, setIsImagePreview] = useState({
    isPreview: false,
    url: '',
  });
  const [formValues, setFormValues] = useState({
    make: null,
    model: null,
    range: null,
  });

  const handleImagePreview = (file) => {
    if (!file.type.startsWith('image/'))
      return toast.error(
        `Image ${file.type} is not supported, please upload an image.`
      );
    setFile(file);
    const url = URL.createObjectURL(file);
    setIsImagePreview({
      isPreview: true,
      url,
    });
  };

  const handleReset = () => {
    setFormValues({
      make: null,
      model: null,
      range: null,
    });
    setIsImagePreview({
      isPreview: false,
      url: '',
    });
    setFile(null);
    setManufactureList(dashboard?.vehicleManufactures || []);
    setModels([]);
  };

  const getVehicleManufacture = async () => {
    const result = await handleApiCall({
      method: 'get',
      url: '/vehicle-manufacture',
    });
    setManufactureList(result?.data?.vehicleManufactur);
    if (result?.status === 200)
      dispatch(toogleVehicleManufactures(result?.data?.vehicleManufactur));
  };

  const handleAddVehicles = async () => {
    if (!formValues?.make || !formValues?.model)
      return toast.error('All fields are required');
    let data = new FormData();
    if (file) data.append('image', file);
    data.append('make', formValues?.make);
    data.append('model', formValues?.model);
    data.append(
      'range',
      formValues?.range ||
        models?.filter((model) => model?._id === formValues?.model)[0]?.range
    );
    data.append('userId', auth?.userInfo?.user?._id);

    const result = await handleApiCall({
      method: 'post',
      url: '/vehicle',
      data,
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    if (result?.status === 201) {
      toast.success(result?.data?.message);
      setIsVehicleModal(false);
      handleReset();
      getMyVehicles(result?.data?.vehicle);
    }
  };

  const valid = () => {
    if (formValues.make && formValues.model) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    getVehicleManufacture();
  }, []);

  return (
    <Transition.Root show={isVehicleModal || false} as={Fragment}>
      <Dialog
        as='div'
        className='relative z-10'
        initialFocus={cancelButtonRef}
        onClose={() => setIsVehicleModal(true)}
      >
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center py-4 text-center sm:items-center sm:p-0'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <Dialog.Panel className='w-full relative transform  bg-transparent text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-4xl'>
                <div className='ez__StepForm w-full bg-white p-4 rounded-lg'>
                  <div className='ez__Title w-full p-4 flex items-center justify-between flex-row'>
                    <h3 className='text-ezBlack font-semibold text-lg'>
                      Add a Vehicle
                    </h3>
                    <button
                      type='button'
                      onClick={() => {
                        setIsVehicleModal(false);
                        handleReset();
                      }}
                      className='bg-white text-ezGreen rounded-full'
                    >
                      <MdCancel className='w-8 h-8 text-ezDarkGray' />
                    </button>
                  </div>
                  <hr className='border border-gray-300 mx-4' />
                  <div className='ez__Form w-full grid grid-cols-1 md:grid-cols-2 gap-x-5'>
                    <div className='p-4 bg-white rounded-lg h-fit'>
                      <div className='block  w-full text-sm sm:text-base text-ezGray'>
                        <h2 className='font-semiBold text-ezDarkGray text-lg mb-3'>
                          SELECT YOUR VEHICLE DETAILS
                        </h2>
                        <h4 className='mb-1'>MAKE</h4>
                        <Select
                          className={`basic-single mb-4 ${
                            manufactureList?.length <= 0 ? 'disableSelect' : ''
                          }`}
                          classNamePrefix='select'
                          placeholder='Select Make*'
                          isClearable={true}
                          isSearchable={true}
                          name='make'
                          styles={colourStyles}
                          value={
                            formValues?.make && {
                              label: manufactureList?.filter(
                                (vehicle) => vehicle?._id === formValues?.make
                              )[0]?.make,
                              value: formValues?.make,
                            }
                          }
                          onChange={(e) => {
                            setFormValues({
                              ...formValues,
                              make: e?.value,
                              model: '',
                              range: '',
                            });
                            setModels(
                              manufactureList?.filter(
                                (model) => model?._id === e?.value
                              )[0]?.models || []
                            );
                          }}
                          options={manufactureList?.map((vehicle) => {
                            return {
                              label: vehicle?.make,
                              value: vehicle?._id,
                            };
                          })}
                        />
                        <h4 className='mb-1'>MODEL</h4>
                        <Select
                          className={`basic-single mb-4 ${
                            models?.length <= 0 ? 'disableSelect' : ''
                          }`}
                          classNamePrefix='select'
                          placeholder='Select Model'
                          isClearable={true}
                          isSearchable={true}
                          styles={colourStyles}
                          name='model'
                          value={
                            formValues?.model && {
                              label: models?.filter(
                                (model) => model?._id === formValues?.model
                              )[0]?.model,
                              value: formValues?.model,
                            }
                          }
                          onChange={(e) => {
                            setFormValues({
                              ...formValues,
                              model: e?.value,
                              range: '',
                            });
                          }}
                          isDisabled={models?.length <= 0 ? true : false}
                          options={models?.map((model) => {
                            return {
                              label: model?.model,
                              value: model?._id,
                            };
                          })}
                        />
                        <h4 className='mb-1'>RANGE</h4>
                        <input
                          type='number'
                          placeholder='Vehicle Range'
                          value={formValues?.range || ''}
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              range: e?.target?.value.slice(0, 4),
                            })
                          }
                          className='block mb-4 ez__FormInput w-full  items-center justify-between border border-gray-400 rounded-md px-3 py-2'
                        />
                        <div className='block'>
                          <GrayBtn
                            isDisabled={isApiLoading}
                            handleEvent={handleAddVehicles}
                            classNameCondition={
                              valid() === true && !isApiLoading
                            }
                            text='Add Vehicle'
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      className={`flex flex-col justify-left p-4 bg-white rounded-lg w-auto max-h-[600px] ${
                        isImagePreview?.isPreview ? 'h-fit mb-5' : ''
                      }`}
                    >
                      <div className='ez__VehicleImage relative block w-full h-full m-auto'>
                        <h2 className='font-semiBold text-ezDarkGray text-lg mb-3'>
                          UPLOAD IMAGE
                        </h2>
                        {isImagePreview?.isPreview ? (
                          <div className='relative border-2 border-ezGray rounded-xl w-full h-full overflow-y-hidden overflow-x-hidden'>
                            <img
                              src={isImagePreview?.url}
                              alt='Vehicle'
                              className='w-full max-h-[500px] object-cover rounded-xl'
                            />
                            <button
                              onClick={() => {
                                setFile(null);
                                setIsImagePreview({
                                  isPreview: false,
                                  url: '',
                                });
                              }}
                              className='absolute top-2 right-2 bg-white rounded-full'
                            >
                              <MdCancel className='w-5 h-5 text-ezRed' />
                            </button>
                          </div>
                        ) : (
                          <FileUploader
                            handleChange={handleImagePreview}
                            name='file'
                            maxSize={10}
                            label='Upload or drag & drop an image here'
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
